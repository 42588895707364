
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function singleVehicleGarageRT () {
    return _createElement('div', { 'className': 'garage-container' }, this.template === 'active' ? _createElement('div', {
        'className': 'active-container cmTemplate_active',
        'key': '33'
    }, _createElement('div', {}, 'Select Vehicle'), _createElement('span', { 'className': 'material-symbols-outlined bike' }, ' two_wheeler ')) : null, this.template === 'locked' ? _createElement('div', {
        'className': 'locked-container cmTemplate_locked',
        'key': '238'
    }, _createElement('span', { 'className': 'model' }, this.vehicleString)) : null);
}
        export const componentNames = []